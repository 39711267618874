import React from 'react';
import { graphql, HeadProps } from 'gatsby';
import NotFound from 'pageComponents/NotFound';

const NotFoundPage = () => {
  return (
    <>
      <NotFound />
    </>
  );
};

export default NotFoundPage;

export function Head(props: HeadProps) {
  return <>
    <meta name='robots' content='noindex, nofollow' />
    <title>404 | Safebrok</title>;
  </>;
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
